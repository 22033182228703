import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private navCtrl: NavController) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401].includes(err.status) && this.authenticationService.accessTokenValue) {
        if (!this.authenticationService.authenticationRetried) {
          this.authenticationService.authenticationRetried = true;
          this.authenticationService.stopRefreshTokenTimer();
          this.authenticationService.refreshToken(this.authenticationService.refreshTokenValue).pipe(first()).toPromise().then(() => {
            this.authenticationService.authenticationRetried = false;
          }).catch(() => {
            this.authenticationService.authenticationRetried = false;
            // auto logout if 401 response returned from api
            this.authenticationService.logout().then();
            this.navCtrl.navigateRoot('/home').then();
          });
        }
      } else if ([401].includes(err.status) &&
        (((err && err.error && err.error.message) || err.statusText) === 'RefreshToken invalid')) {
        this.authenticationService.logout().then();
        this.navCtrl.navigateRoot('/home').then();
      }

      const error = err.error;
      console.error(error.message);
      return throwError(error);
    }));
  }
}
