import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeGuard } from './home/home.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    canActivate: [HomeGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsModule)
  },
  {
    path: 'crush-word-detail/:id',
    loadChildren: () => import('./catchphrase-detail/catchphrase-detail.module')
      .then(m => m.CatchphraseDetailPageModule),
  },
  {
    path: 'user-profile/:username',
    loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'legal-notices',
    loadChildren: () => import('./legal-notices/legal-notices.module').then( m => m.LegalNoticesModule)
  },
  {
    path: 'network-error',
    loadChildren: () => import('./network-error/network-error.module').then(m => m.NetworkErrorPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
