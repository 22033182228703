import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomeGuard implements CanActivate, OnDestroy {

  private storageSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private authenticationService: AuthService,
    private storageService: StorageService,
    private platform: Platform,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const subject = new Subject<boolean>();
    this.storageSubscription = this.storageService.storage.subscribe(async res => {
      if (res === 'created') {
        const refreshToken: string = await this.storageService.get('refreshToken');
        if (refreshToken) {
          subject.next(true);
        } else {
          this.navCtrl.navigateRoot(['/login']).then();
          subject.next(false);
        }
      }
    });
    return subject.asObservable();
  }

  ngOnDestroy(): void {
    this.storageSubscription.unsubscribe();
  }
}
