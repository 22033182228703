import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { LoginUserGoogleDto } from '../dto/auth/login-user-google.dto';
import { LoggedUserDto } from '../dto/auth/logged-user.dto';
import { LoginUserEmailDto } from '../dto/auth/login-user-email.dto';
import { UserDto } from '../dto/users/user.dto';
import { SERVER_URL } from '../../environments/environment';
import { CreateUserEmailDto } from '../dto/users/create-user-email.dto';
import { SendResetPasswordDto } from '../dto/auth/send-reset-password.dto';
import { ResetPasswordDto } from '../dto/auth/reset-password.dto';
import { UpdatePasswordDto } from '../dto/auth/update-password.dto';
import { UpdatePushTokenDto } from '../dto/auth/update-push-token.dto';

@Injectable()
export class AuthRepositoryService {
  authUrl = SERVER_URL + '/api/auth/';  // URL to web api
  handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('AuthService');
  }

  loginGoogle(user: LoginUserGoogleDto): Observable<LoggedUserDto> {
    return this.http.post<LoggedUserDto>(this.authUrl + 'login/google', user);
  }

  loginEmail(user: LoginUserEmailDto): Observable<LoggedUserDto> {
    return this.http.post<LoggedUserDto>(this.authUrl + 'login/email', user);
  }

  login(): Observable<UserDto> {
    return this.http.get<UserDto>(this.authUrl + 'login');
  }

  refreshToken(refreshToken: string): Observable<{ accessToken: string }> {
    return this.http.post<{ accessToken: string }>(this.authUrl + 'refresh', { refreshToken });
  }

  registerUserEmail(createUserEmail: CreateUserEmailDto): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>(this.authUrl + 'register/email', createUserEmail);
  }

  sendResetPassword(resetPassword: SendResetPasswordDto): Observable<{ result: boolean }> {
    return this.http.put<{ result: boolean }>(this.authUrl + 'send-reset-password', resetPassword);
  }

  resetPassword(resetPassword: ResetPasswordDto): Observable<{ result: boolean }> {
    return this.http.put<{ result: boolean }>(this.authUrl + 'reset-password', resetPassword);
  }

  updatePassword(updatePassword: UpdatePasswordDto) {
    return this.http.put<{ refreshToken: string }>(this.authUrl + 'update-password', updatePassword);
  }

  updatePushTokenDto(updatePushToken: UpdatePushTokenDto): Observable<{ result: boolean }> {
    return this.http.put<{ result: boolean }>(this.authUrl + 'update-push-token', updatePushToken);
  }
}
