import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { CatchphraseDto } from '../dto/catchphrases/catchphrase.dto';
import { CreateCatchphraseDto } from '../dto/catchphrases/create-catchphrase.dto';
import { SERVER_URL } from '../../environments/environment';
import { UpdateCatchphraseAnonymousDto } from '../dto/catchphrases/update-anonymous.dto';
import { FilterByDto } from '../dto/catchphrases/filterBy.dto';
import { EditCatchphraseDto } from '../dto/catchphrases/edit-catchphrase.dto';

@Injectable()
export class CatchphrasesRepositoryService {
  catchphrasesUrl = SERVER_URL + '/api/catchphrases/';  // URL to web api
  handleError: HandleError;


  constructor(
    private http: HttpClient) {
  }

  getCatchphrase(catchphraseId: string): Observable<CatchphraseDto> {
    return this.http.get<CatchphraseDto>(this.catchphrasesUrl + catchphraseId);
  }

  getMultipleCatchphrases(skip: number = 0, tags?: string[], follows?: string[],
                          filterBy: FilterByDto = FilterByDto.DATE): Observable<CatchphraseDto[]> {
    const url = new URL(this.catchphrasesUrl + 'multiple');
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    urlSearchParams.append('filterBy', String(filterBy));
    if (tags && tags.length > 0) {
      tags.map(tag => {
        urlSearchParams.append('filters', tag);
      });
    }
    if (follows && follows.length > 0) {
      follows.map(follow => {
        urlSearchParams.append('follows', follow);
      });
    }
    url.search = urlSearchParams.toString();
    return this.http.get<CatchphraseDto[]>(url.toString());
  }

  getSubmittedCatchphrases(skip: number = 0, tags?: string[]): Observable<CatchphraseDto[]> {
    const url = new URL(this.catchphrasesUrl + 'submitted');
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    if (tags && tags.length > 0) {
      tags.map(tag => {
        urlSearchParams.append('filters', tag);
      });
    }
    url.search = urlSearchParams.toString();
    return this.http.get<CatchphraseDto[]>(url.toString());
  }

  validateCatchphrase(id: string): Observable<CatchphraseDto> {
    return this.http.post<CatchphraseDto>(this.catchphrasesUrl + 'validate/' + id, {});
  }

  deleteCatchphrase(id: string): Observable<{ catchphraseId: string }> {
    return this.http.delete<{ catchphraseId: string }>(this.catchphrasesUrl + 'delete/' + id);
  }
  getByUser(username: string, skip: number = 0): Observable<CatchphraseDto[]> {
    const url = new URL(this.catchphrasesUrl + 'by-user/' + username);
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    url.search = urlSearchParams.toString();
    return this.http.get<CatchphraseDto[]>(url.toString());
  }

  post(catchphrase: CreateCatchphraseDto): Observable<CatchphraseDto> {
    return this.http.post<CatchphraseDto>(this.catchphrasesUrl + 'create', catchphrase);
  }

  likeCatchphrase(id: string): Observable<CatchphraseDto> {
    return this.http.post<CatchphraseDto>(this.catchphrasesUrl + 'like/' + id, {});
  }

  unlikeCatchphrase(id: string): Observable<CatchphraseDto> {
    return this.http.post<CatchphraseDto>(this.catchphrasesUrl + 'unlike/' + id, {});
  }

  setIsAnonymous(updateCatchphraseAnonymous: UpdateCatchphraseAnonymousDto): Observable<{ result: boolean }> {
    return this.http.put<{ result: boolean }>(this.catchphrasesUrl + 'update-anonymous', updateCatchphraseAnonymous);
  }

  editCatchphrase(id: string, catchphrase: EditCatchphraseDto): Observable<CatchphraseDto> {
    return this.http.put<CatchphraseDto>(this.catchphrasesUrl + 'edit/' + id, catchphrase);
  }
}



