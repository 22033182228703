import { Injectable } from '@angular/core';

@Injectable()
export class ConnectIdService {
  private _googleTokenId: string;

  constructor() {
  }

  set googleTokenId(value: string) {
    this._googleTokenId = value;
  }

  get googleTokenId(): string {
    return this._googleTokenId;
  }
}
