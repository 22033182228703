import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TagDto } from '../dto/tags/tag.dto';
import { SERVER_URL } from '../../environments/environment';

@Injectable()
export class TagsRepositoryService {
  tagsUrl = SERVER_URL + '/api/tags/';  // URL to web api

  constructor(
    private http: HttpClient) {
  }

  getMultipleTags(skip: number = 0, tag?: string): Observable<TagDto[]> {
    const url = new URL(this.tagsUrl + 'multiple');
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    if (tag && tag.trim() !== '') {
      urlSearchParams.append('filter', tag);
    }
    url.search = urlSearchParams.toString();
    return this.http.get<TagDto[]>(url.toString());
  }

  getAll(): Observable<TagDto[]> {
    return this.http.get<TagDto[]>(this.tagsUrl+ 'all');
  }
}
